import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../services/index';


export const getContest = createAsyncThunk('contest/getContest', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.ContestList(datas);
        const data = res.data;
        return { data: data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getWinningPriceList = createAsyncThunk('contest/getWinningList', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.ContestWinningPriceList(datas);
        return { data: res.data };

    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getContextLists = createAsyncThunk('contest/getList', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.ContestLists(datas);
        return { data: res.data };

    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getUpcomingContextLists = createAsyncThunk('upcoming/contest/list', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.UpcomingContextLists(datas);
        return { data: res.data };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getContestJoinedList = createAsyncThunk('contestJoined/getList', async (datas, thunkAPI) => {
    try {
        const res = await ApiService.ContestJoinedList(datas);
        return { data: res.data };

    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});


// Define the initial state
const initialState = {
    data: [],
    // totalItems: 1,
    // params: {},
    // allData: [],
    // totalPages: 0,
    // currentPage: 1,
    loading: 'idle',
    error: null,
    winningData: null,
    contextList: null,
    contestList: null,
    contestJoinedList: null
};

export const contestSilce = createSlice({
    name: 'contest',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getContest.fulfilled, (state, action) => {
            state.data = action.payload.data;
        })
            .addCase(getWinningPriceList.fulfilled, (state, action) => {
                state.winningData = action.payload.data;
            }).addCase(getContextLists.fulfilled, (state, action) => {
                state.contextList = action.payload.data;
            }).addCase(getUpcomingContextLists.fulfilled, (state, action) => {
                state.contestList = action.payload.data;
            })
            .addCase(getContestJoinedList.fulfilled, (state, action) => {
                state.contestJoinedList = action.payload.data;
            });
    }
});

export default contestSilce.reducer;