/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-useless-catch */

// ApiService.getData('/api/endpoint')
import axios from 'axios';
class ApiService {
    constructor() {
        this.api = axios.create({
            baseURL: process.env.REACT_APP_API_URL, // Replace with your API's base URL
        });

        this.api.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem('accessToken');
                if (token) {
                    const cleanedToken = token.replace(/^"(.*)"$/, '$1');
                    config.headers.Authorization = `Bearer ${cleanedToken}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    // Example GET request method
    async getData(endpoint) {
        return this.api.get(endpoint);
    }

    async postData(endpoint, data) {
        return this.api.post(endpoint, data);
    }

    data() {
        const datas = [{ "phone": "12345" }, { "phone": "12345" }];
        return datas;
    }

    async Login(data) {
        try {
            const response = await this.api.post('/login', data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    // Restaurant
    async Restaurants(params) {
        return this.api.post('/restaurant', params, { params: params });
    }
    // Restaurant search
    async RestaurantSearch(data) {
        return this.api.post('/restaurantfood/filter', data);
    }
    // Add Admin Restaurant 
    async AddAdminRestaurant(data) {
        return this.api.post('/admin/restaurant/create', data);
    }
    async RestaurantDetail(restaurantId) {
        return this.api.post('/restaurant/details', { id: restaurantId });
    }
    async LoginOtpVerify(data) {
        return this.api.post('/verifyotp', data);
    }
    async Food(data) {
        return this.api.post('/food/filter', { params: data });
    }
    async RestaurantFood(restaurantId) {
        return this.api.post('/restaurant-food', { id: restaurantId });
    }
    async RestaurantImages(data) {
        return this.api.post('/restaurant/media/images', { restaurantId: data.restaurantId }, { params: data });
    }
    async UserRestaurantReview(data) {
        return this.api.post('/user-restaurant/comment', { id: data?.restaurantId }, { params: data });
    }
    async RestaurantAboutUs(restaurantId) {
        return this.api.post('/restaurant/aboutus', { restaurantId: restaurantId });
    }
    async RestaurantMustTryFood(data) {
        return this.api.post('/restaurant/musttry-food', data, { params: data });
    }
    async ReviewersList(data) {
        return this.api.get('/review', { params: data });
    }
    async RestaurantMenuItems(restaurantId) {
        return this.api.post('/restaurant-food', {
            id: restaurantId
        });
    }
    async RestaurantAllComments(data) {
        return this.api.post('/restaurant/comment', data, { params: data });
    }
    async RestaurantLikeDisLike(data) {
        return this.api.post('/review/likeordislike', data);
    }

    async RestaurantReviewedReviewersList(data) {
        return this.api.post('/restaurant/reviewed-reviewer/list', data, { params: data });
    }
    async RestaurantReviewedReviewerFoodList(data) {
        return this.api.post('/restaurant/reviewer-reviewed-food/list', data, { params: data });
    }
    async RestaurantReviewedFood(data) {
        return this.api.post('/restaurant/reviewed-food', data, { params: data });
    }
    async GetRestaurantDetail(data) {
        return this.api.post('/restaurant/edit', data);
    }
    async RestaurantFoodSearch(data) {
        return this.api.post('/restaurant-food/search', data);
    }

    // Store Api's
    async RestaurantReview(data) {
        return this.api.post('/review/store', data);
    }
    async RestaurantEditReview(data) {
        return this.api.post('/review/edit', data);
    }
    async RestaurantReviewedUserList(restaurantId) {
        return this.api.get(`/restaurant/${restaurantId}/reviewed/by`);
    }

    // Users
    async CreateWishList(data) {
        return this.api.post('/wishlist/create', data);
    }
    async Profile(data) {
        return this.api.get('/profile');
    }
    async ProfileReviewRestaurant(data) {
        return this.api.post('/profile/review-restaurant', data, { params: data });
    }
    async ProfileWishList() {
        return this.api.post('/wishlist');
    }
    async Country() {
        return this.api.get('/getcountry');
    }
    async State(id) {
        return this.api.get(`/state/${id}`);
    }
    async City(id) {
        return this.api.get(`/city/${id}`);
    }
    async ProfileImageUpload(data) {
        return this.api.post('/profile/image', data);
    }
    async UserUpdate(data) {
        return this.api.post('/user/update', data);
    }
    async UserFollowers(data) {
        return this.api.get('/user-followers/list', { params: data });
    }
    async UserFollowings(data) {
        return this.api.get('/user-followings/list', { params: data });
    }
    async FollowAPI(data) {
        return this.api.post(`/following/${data?.id}`, data);
    }
    async RecommendedRestaurants(data) {
        return this.api.post('/profile/review-restaurant', data, { params: data });
    }
    async RecommendedByMe(data) {
        return this.api.post('/recommended-user/by-me', data, { params: data });
    }
    async Dashboard() {
        return this.api.get('/user/dashboard/details');
    }
    async UserProfileRemove(data) {
        return this.api.post('/user/profile/remove', data);
    }

    //Public Profile Apis
    async Reviews(data) {
        return this.api.post(`/reviewer/${data?.userId}/restaurant`, data, { params: data });
    }
    async RecommendedRestaurant(data) {
        return this.api.post('/recommendedRestaurant', data, { params: data });
    }
    async ReviewerHighRating(data) {
        return this.api.post(`/reviewer/${data?.userId}/restaurant-highrating`, data, { params: data });
    }
    // Reviewers Followers
    async ReviewersFollowers(data) {
        return this.api.post('/reviewer-followers/list', data, { params: data });
    }
    // Reviewers Followings
    async ReviewersFollowings(data) {
        return this.api.post('/reviewer-followings/list', data, { params: data });
    }
    // Reviewers Filter
    async ReviewerFilter(data) {
        return this.api.post('/review/filter', data);
    }

    //Recommended Api
    async RecommendedUserProfile(data) {
        return this.api.post('/store/recommended-user', data);
    }
    // Recommended Restaurant 
    async CreateRecommendedRestaurant(data) {
        return this.api.post('/recommendedRestaurant/create', data);
    }

    // Reviewer Profile
    async ReviewerProfile(userId) {
        return this.api.get(`/reviewer-profile/${userId}`);
    }

    // FAQ
    async FAQ(data) {
        return this.api.get('/faq', { params: data });
    }
    //Privacy Policy
    async PrivacyPolicy(data) {
        return this.api.get('/privacy-policy');
    }
    //Terms and Conditions
    async TermsAndCondtion() {
        return this.api.get('/terms-and-condition');
    }
    // Contact Us
    async ContactUs() {
        return this.api.get('/contact');
    }

    // Contest 
    async ContestList(data) {
        return this.api.get('/future/contest/list', { params: data });
    }
    async contestDetail(data) {
        return this.api.post('/contest/edit', data);
    }
    async ContestJoin(data) {
        return this.api.post('/contest/join', data);
    }
    async ContestJoinedList(data) {
        return this.api.post('/contest/joined/list', data, { params: data });
    }
    async ContestWinningPriceList(data) {
        return this.api.get('/contest/winning/price/list', { params: data });
    }
    async ContestLists(data) {
        return this.api.get('/contest/list', { params: data });
    }
    async ContestAllLists(data) {
        return this.api.get('/contest/list/all-data', { params: data });
    }
    async UpcomingContextLists(data) {
        return this.api.get('/upcoming-contest/list/all', { params: data });
    }
    // Customer Search Contest
    async CustomerSearch(data) {
        return this.api.post('/contest/customer/search', data, { params: data });
    }

    // Review update
    async ReviewUpdate(data) {
        return this.api.post('/review/update', data);
    }
    async GetCommand(data) {
        return this.api.post('/getcomment', data, { params: data });
    }
    async ChildReviewUpdate(data) {
        return this.api.post('/child-review/update', data);
    }
    // Food Type
    async FoodType(data) {
        return this.api.get('/app-foodtype', { params: data });
    }
    // User Delete
    async UserDelete(userId) {
        return this.api.delete(`/user/delete/${userId}`);
    }

    // Not Authorized HomePage
    async NotAuthorizedLayout() {
        return this.api.get('/auth/dashboard/details');
    }
    // Cuisine get
    async CuisineList(data) {
        return this.api.get('/cuisine', { params: data });
    }
    async ContactUsPost(data) {
        return this.api.post('/contactus/store', data);
    }
    async RestaurantRatingFilter(data) {
        return this.api.post('/restaurant-rating/filter', data);
    }
}

export default new ApiService();